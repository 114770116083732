import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDocs,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";

import React, { useEffect, useState } from "react";

import "./Player.css";

import GifCarregando from "./../../../assets/carregando.gif";


import ReprodutorDeAudio from "./ReprodutorDeAudio/ReprodutorDeAudio";
import Patrocinios from "./Propagandas/Patrocinios/Patrocinios";
import Anuncios from "./Propagandas/Anuncios/Anuncios";
import ContagemOuvintes from "./ContagemOuvintes/ContagemOuvintes";
import BotaoVoltar from "./BotaoVoltar/BotaoVoltar";


const firebaseApp = initializeApp({
  apiKey: "AIzaSyBM_L0fGOuHzHn_vhwCgYBqFoYog_tspbE",
  authDomain: "ouvimix.firebaseapp.com",
  projectId: "ouvimix",
});

const Player = () => {
  const [canal, setCanal] = useState(null);
  const [patrocinadores, setPatrocinadores] = useState([]);

  const db = getFirestore(firebaseApp);
  const canaisCollectionRef = collection(db, "canais");
  const patrocinadoresCollectionRef = collection(db, "patrocinadores");

  useEffect(() => {
    const getPatrocinadores = async () => {
      let data;
      data = await getDocs(patrocinadoresCollectionRef);
      const allPatrocinadores = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPatrocinadores(allPatrocinadores);
      //console.log(allPatrocinadores);
    };
    getPatrocinadores();
  }, []);

  useEffect(() => {
    const getCanal = async (canalId) => {
      const canalDocRef = doc(canaisCollectionRef, canalId);
      const canalSnapshot = await getDoc(canalDocRef);
      if (canalSnapshot.exists()) {
        const canalData = { id: canalSnapshot.id, ...canalSnapshot.data() };
        setCanal(canalData);
        //console.log(canalData);
      } else {
        console.log("Documento não encontrado!");
      }
    };

    const queryParams = new URLSearchParams(window.location.search);

    const canalId = queryParams.get("canalID");
    getCanal(canalId);
  }, []);

  //Patrocinadores
  const imagens = patrocinadores.map(
    (patrocinador) => patrocinador.logomarcaURL
  );
  const links = patrocinadores.map(
    (patrocinador) => patrocinador.link
  );

  //Checa se o canal reproduz video e altera entre player de audio ou video

  if (canal != null) {
    if (!canal.video) {
      // player audio
      return (
        <div className="bg-PlayerPage">
          <div className="container">
            <div className="containerPagina">

              <BotaoVoltar url="/canais" posicao="cima"/>

              <div className="containerInformacoes">
                <div className="logoContainer">
                  <img
                    className="logo"
                    src={canal.iconeURL}
                    alt="logo do canal"
                  />
                </div>
                <h2 className="nomeCanal">{canal.nome}</h2>
              </div>
              <div className="containerPropagandas">
                <div className="patrocinios">
                  <h6>Patrocinadores:</h6>
                  <Patrocinios imagens={imagens} links={links} duracaoSegundos={5} />
                  <h6>Toque para visitar o patrocinador</h6>
                </div>
                <div className="anuncios">
                  {/*<Anuncios/>*/}
                  {/*<ContagemOuvintes rotaDB_ContagemOuvintes = {canal.rotaOuvintes}/>*/}
                </div>
              </div>
              <div className="containerReprodutor">
                {<ReprodutorDeAudio audioURL={canal.streamURL} />}
                {/*<AudioClipper audioUrl={canal.streamURL}/>*/}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      //player video
      return (
        <div className="bg-PlayerPage">
          <div className="container">
            <BotaoVoltar url="/canais" posicao="baixo"/>

            <div className="containerVideo">
              <iframe className="videoIFrame"
                

                //checa se o canal ja tem um player customizado, se n, usa o do youtube
                src={
                  canal.customPlayer
                    ? `${canal.streamURL}`
                    : `https://www.youtube.com/embed/${canal.streamURL}?modestbranding=1&showinfo=0`
                }

                //src={`https://www.youtube.com/embed/${canal.streamURL}?modestbranding=1&showinfo=0`}
                
                title={canal.nome}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="1"
                showinfo="0"
                autoplay="1"
              ></iframe>
            </div>

            <div className="containerInfoVideo">
              <h4>{canal.nome}</h4>
              {/*<ContagemOuvintes rotaDB_ContagemOuvintes = {canal.rotaOuvintes}/>*/}
              
            </div>

            <div className="containerPropagandas">
                <div className="patrocinios">
                  <h6>Patrocinadores:</h6>
                  <Patrocinios imagens={imagens} links={links} duracaoSegundos={5} />
                  <h6>Toque para visitar o patrocinador</h6>
                </div>
                <div className="anuncios">{/*<Anuncios/>*/}</div>
              </div>
             
          </div>
        </div>
      );
    }
  } else {
    //pagina carregamento
    return (
      <div className="telaCarregamentPlayer">
        <div className="infoCarregamento">
          <img src={GifCarregando} alt="" />
          <h4 className="txtCarregando">Carregando</h4>
        </div>
      </div>
    );
  }
};

export default Player;
