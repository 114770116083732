import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  orderBy,
} from "firebase/firestore";


import React, { useEffect, useState } from "react";

import ListaCanais from "./../../ListaCanais/ListaCanais";

import style from "./CanaisNew.module.css";
import Filtro from "./Filtro/Filtro";
import Modal from "../../Modal/Modal";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBM_L0fGOuHzHn_vhwCgYBqFoYog_tspbE",
  authDomain: "ouvimix.firebaseapp.com",
  projectId: "ouvimix",
});

const Canais = () => {
  const [canais, setCanais] = useState([]);
  const [filtro, setFiltro] = useState("");

  const db = getFirestore(firebaseApp);
  const canaisCollectionRef = collection(db, "canais");
  const filtroQuery = query(canaisCollectionRef, orderBy("nome"));

  //const [modalOpen, setModalOpen] = useState(true); // Adicione o estado para controlar o modal



  useEffect(() => {
    const getCanais = async () => {
      let data;
      if (filtro) {
        data = await getDocs(filtroQuery);
        const filteredCanais = data.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((canal) =>
            canal.nome.toLowerCase().includes(filtro.toLowerCase())
          ).filter((canal) =>
          canal.visivel
        )
        setCanais(filteredCanais);
      } else {
        data = await getDocs(canaisCollectionRef);
        const allCanais = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })).filter((canal) =>
        canal.visivel
      );
        setCanais(allCanais);
      }
    };
    getCanais();
  }, [filtro]);


  /*const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };*/
  

  return (
    <div className={style.bgCanaisPage}>
      <div className={style.header}>
        <h1>Tudo que você quer escutar em um só lugar!</h1>
        <p className="header-text">
          Navegue por vários conteúdos diferentes. Pesquise o canal do seu
          interesse e fique entretido!
        </p>
      </div>

      <div className={style.barraDeBuscaContainer}>
        <div className={style.barraDeBusca}>
          <img
            className={style.iconeBarraDeBusca}
            src="https://images.tcdn.com.br/files/934950/themes/11/img/settings/lupa-branca-png-3-Transparent-Images.png?327a90fe598b5b5bf3e881dfe64f9582"
            alt=""
          />
          <input
            className={style.inputFiltro}
            type="text"
            placeholder="Pesquise o que deseja escutar"
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
          />
        </div>
        {/*<Filtro />*/}
      </div>

      <div className={style.listaCanaisContainer}>
        <ListaCanais canais={canais} />
      </div>

      
      {/* Conteúdo do Modal 
      <Modal isOpen={modalOpen} onClose={closeModal}>
        conteudo do modal

        <h2>Atenção</h2>
        <p>Alguns canais podem não reproduzir audio em certos navegadores devido às <a href="https://support.google.com/chrome/answer/99020?hl=pt-BR&co=GENIE.Platform%3DDesktop&oco=1#zippy=%2Cver-toda-a-p%C3%A1gina-n%C3%A3o-segura%2Cdesativar-avisos-sobre-sites-perigosos-e-enganosos%2Cdesativar-ou-reativar-o-google-play-protect" target="_blank">Políticas de Segurança</a> dos mesmos.</p>
      </Modal>*/}
      
    </div>
  );

  return (
    <div className={style.bgCanaisPage}>
      <div className={style.header}>
        <div className="textoInfor">
          <h1>Tudo que você quer escutar em um só lugar!</h1>
          <p className="header-text">
            Navegue por vários conteúdos diferentes. Pesquise o canal do seu
            interesse e fique entretido!
          </p>
        </div>
        <div className="divInput">
          <input
            type="text"
            placeholder="Pesquise o canal que deseja escutar"
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
          />
        </div>
      </div>
      <div className="container">
        <ListaCanais canais={canais} />
      </div>
 

      

    </div>
  );
};

export default Canais;
