import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set, get, increment } from "firebase/database";
import IconOuvintes from "./../../../../assets/iconOuvintes.png";
import style from "./ContagemOuvintes.module.css";

const firebaseConfig = {
    apiKey: "AIzaSyBM_L0fGOuHzHn_vhwCgYBqFoYog_tspbE",
    authDomain: "ouvimix.firebaseapp.com",
    projectId: "ouvimix",
  };

const firebaseApp = initializeApp(firebaseConfig);

const ContagemOuvintes = ({rotaDB_ContagemOuvintes}) => {
  const [ouvintes, setOuvintes] = useState(0);

  //const [carregouForaDaPagina, setCarregouForaDaPagina] = useState(false);
  
  useEffect(() => {
    const ouvintesRef = ref(getDatabase(firebaseApp), rotaDB_ContagemOuvintes);

    const updateOuvintes = (snapshot) => {
      if (snapshot.exists()) {
        setOuvintes(snapshot.val());
      }
    };

    const incrementOuvintes = () => {
      const ouvintesRef = ref(getDatabase(firebaseApp), rotaDB_ContagemOuvintes);
      set(ouvintesRef, increment(1));
      console.log("Entrou");
    };

    // iniciar valores e check carregar fora da pagina
    onValue(ouvintesRef, updateOuvintes);
    
    if(document.visibilityState === "visible"){
       incrementOuvintes();
       //setCarregouForaDaPagina(true);
    }
    
    //console.log(carregouForaDaPagina);
  
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        incrementOuvintes();
        console.log("entrou visible");
      } else {
        decrementOuvintes();
        console.log("saiu visible");
      }
    };

    const decrementOuvintes = () => {
      const ouvintesRef = ref(getDatabase(firebaseApp), rotaDB_ContagemOuvintes);

      get(ouvintesRef).then((snapshot) => {
        const valorAtual = snapshot.val();
    
        if (valorAtual > 0) {
          set(ouvintesRef, valorAtual - 1);
        }
      });
      console.log("Saiu");
    };

    const handlePageBlur = () => {
      decrementOuvintes();
    };


    //window.addEventListener("beforeunload", decrementOuvintes);
    window.addEventListener("visibilitychange", handleVisibilityChange);
    //window.addEventListener("blur", handlePageBlur);

    return () => {
      //window.removeEventListener("beforeunload", decrementOuvintes);
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      //window.removeEventListener("blur", handlePageBlur);
    };
  }, [rotaDB_ContagemOuvintes]);


  return (
    <div className={style.infoOuvintes}>
      <img className={style.iconOuvintes} src={IconOuvintes} alt="" />
      <p>{ouvintes}</p>
      
    </div>
  );
};

ContagemOuvintes.propTypes = {
  rotaDB_ContagemOuvintes: PropTypes.string.isRequired,
};

export default ContagemOuvintes;
