import React from 'react';
import PropTypes from "prop-types";

import "./BotaoVoltar.css";
import BotaoVoltarIco from "./../../../../assets/botaoVoltar.png"

const BotaoVoltar = ({url, posicao}) => {
    
    return ( 
        <div className={`botaoVoltar_${posicao}`} onClick={() => {window.location.href = url}}>
                <img className="btnIco" src={BotaoVoltarIco} alt="" />
              </div>
     );
}
 
BotaoVoltar.propTypes = {
    url: PropTypes.string.isRequired,
    posicao: PropTypes.string.isRequired,
  };

export default BotaoVoltar;