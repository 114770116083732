import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="home-container">
      <header className="header"></header>

      <main className="main-content">
        <h1 className="logoAca">OUVIMIX</h1>
        <p className="description">
          Escute Rádios, Web Rádios e Podcasts. Tudo em um só lugar!
        </p>
        <div className="sobre">
          <h2>O que é a OuviMix?</h2>
          <p>
            Informação, lazer e entreterimento bem perto de você! Escute os
            melhores canais de comunicação da sua região e fique sempre
            conectado! A Ouvimix é uma plataforma que reúne diversos meios de
            comunicação em um só lugar para você acessar quando e aonde quiser.
            Busque por Rádios, Web Rádios e Podcasts para escutar e fique sempre
            atualizado!
          </p>
        </div>
        <a href="./canais" className="btn-listen">
          Comece a escutar!
        </a>
      </main>

      <footer className="footer">
        <div className="footer-content">
          <div className="social-links">
            <a
              href="https://www.instagram.com/smartdev_sistemas/"
              target="_blank"
            >
              <img
                className="social-icon"
                src="https://www.edigitalagency.com.au/wp-content/uploads/new-Instagram-logo-white-glyph.png"
                alt="Instagram"
              />
            </a>
          </div>

          <p
            className="linkEmpresa"
            href="https://www.instagram.com/smartdev_sistemas/"
            target="_blank"
          >
            ©Smart DEV. Todos os direitos reservados.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;

