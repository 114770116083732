import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import './Patrocinios.css';

//Funcionalidae: Ao clicar na imagem do patrocinador, abrir pagina


const Patrocinios = ({ imagens, links, duracaoSegundos }) => {
  const [indiceImagem, setIndiceImagem] = useState(0);
  //const [indiceLink, setIndiceLink] = useState(0);
  

  useEffect(() => {
    const intervalo = setInterval(() => {
      setIndiceImagem((indice) => (indice + 1) % imagens.length);
    }, duracaoSegundos * 1000);
    return () => clearInterval(intervalo);
  }, [imagens.length, duracaoSegundos]);

  return (
    <div>
    <img 
      className="logoPatrocinador"
      src={imagens[indiceImagem]}
      alt="l"
      
      
      onClick={() => links[indiceImagem] != null ? window.open(links[indiceImagem], '_blank') : null}

    />
    
    </div>
  );
};

Patrocinios.propTypes = {
  imagens: PropTypes.arrayOf(PropTypes.string).isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  duracaoSegundos: PropTypes.number.isRequired,
};

export default Patrocinios;