import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import style from './Canal.module.css';

import Carregando_Gif from './../../assets/carregando.gif'
import PlayButton from './../../assets/botao-play.png'

const Canal = ({ iconeURL, nome, descricao, categoria, streamURL, idFirebase }) => {
  
  /*const [isStreaming, setIsStreaming] = useState(false);

  useEffect(() => {
    const audio = new Audio(streamURL);
    audio.addEventListener('loadedmetadata', () => {
      setIsStreaming(audio.duration > 0);
    });
  }, [streamURL]);*/


  return (
    <div className={style.canal} onClick={() => {/*if(isStreaming)*/{window.location.href = `/player?canalID=${idFirebase}`}}}> 
      <img className={style.logoCanal} src={iconeURL ? iconeURL : Carregando_Gif} alt="" />

      <div className={style.informacoesCanal}>
        <div className={style.informacoesPrimarias}>
            <strong><p className={style.nomeCanal}>{nome}</p></strong>
            <p>{descricao}</p>
        </div>
        <div className={style.informacoesSecundarias}>
            <p>Lavras da Mangabeira, Ceará, Brasil</p>
            <p>Categoria: {categoria}</p>
            {/*<p>Tags: pop, rock, eclética</p>*/}
        </div>
      </div>

      <div className={style.statusCanal}>
      {/*isStreaming ? (
          <p className={style.statusStreamNoAr}>◉ No Ar!</p>
        ) : (
          <p className={style.statusStreamForaDoAr}>◉ Fora do Ar!</p>
        )}

        {isStreaming ? (<img src={PlayButton} alt="" />) : (null)*/}
        <img src={PlayButton} alt="" />
        </div>
    </div>
  );

  
};

Canal.propTypes = {
    idFirebase: PropTypes.string.isRequired,
    iconeURL: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
    descricao: PropTypes.string.isRequired,
    categoria: PropTypes.string.isRequired,
    streamURL: PropTypes.string.isRequired
};

export default Canal;
