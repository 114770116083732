import React from "react";
import PropTypes from "prop-types";

import Canal from "./../Canal/Canal";

import style from "./ListaCanais.module.css";
import TesteNewCanal from "../TesteNewCanal/TesteNewCanal";

const ListaCanais = ({ canais }) => {
  if (canais != null) {
    return (
      <div className={style.listaCanais}>
        {canais.map((canal) => (
          <Canal
            key={canal.id}
            iconeURL={canal.iconeURL}
            nome={canal.nome}
            descricao={canal.descricao}
            categoria={canal.categoria}
            streamURL={canal.streamURL}
            idFirebase={canal.id}
          />
          /*<TesteNewCanal/>*/
        ))}
      </div>
    );
  }
  else{
    return(<h1>Carregando</h1>);
  }
};

ListaCanais.propTypes = {
  canais: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      iconeURL: PropTypes.string.isRequired,
      nome: PropTypes.string.isRequired,
      descricao: PropTypes.string.isRequired,
      categoria: PropTypes.string.isRequired,
      streamURL: PropTypes.string.isRequired,
      idFirebase: PropTypes.string.isRequired,
    })
  ),
};

export default ListaCanais;
