import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./ReprodutorDeAudio.css";
import BotaoPlay from "./../../../../assets/botao-play.png";
import BotaoMutar from "./../../../../assets/botaoMutar.png";
import BotaoPause from "./../../../../assets/botao-de-pausa.png";
import BotaoDesmutar from "./../../../../assets/botaoDesmutar.png";

const ReprodutorDeAudio = ({ audioURL }) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const audioRef = useRef(null);

  const [isSafari_Browser, setIsSafari_Browser] = useState(false);
  const [isInstagram_Browser, setIsInstagram_Browser] = useState(false);

  useEffect(() => {
    // Verifica se o navegador é o Safari
    const userAgent = navigator.userAgent.toLowerCase();
    setIsSafari_Browser(userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1);
  }, []);

  useEffect(() => {
    // Verifica se o navegador é o Instagram
    const userAgent = navigator.userAgent.toLowerCase();
    setIsInstagram_Browser(userAgent.indexOf("Instagram") !== -1 && userAgent.indexOf("chrome") === -1);
  }, []);


  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMuteUnmute = () => {
    if (isMuted) {
      audioRef.current.muted = false;
    } else {
      audioRef.current.muted = true;
    }
    setIsMuted(!isMuted);
  };

  const handleVolumeChange = (event) => {
    audioRef.current.volume = event.target.value;
  };

  
  const handleLoadStart = () => {
    setIsLoading(true);
  };

  const handleLoadedData = () => {
    setIsLoading(false);
    
    if(isSafari_Browser || isInstagram_Browser){
      setIsPlaying(false);
    }
    else{
      setIsPlaying(true);
    }
    
    //setIsPlaying(isSafari_Browser ? false : true);
    audioRef.current.play();
  };

  return (
    <div className="bg-ReprodutorDeAudio">
      <audio
        ref={audioRef}
        src={audioURL}
        onLoadStart={handleLoadStart}
        onLoadedData={handleLoadedData}
        autoPlay
        type="audio/aac"
      ></audio>


      <div className="containerBotao">
      {!isLoading &&(
      <img
          className="botaoPlayPause"
          src={isPlaying ? BotaoPause : BotaoPlay}
          alt=""
          onClick={togglePlayPause}
        />
        )}
      </div>
      <div className="containerNomeVolume">
      <h4 className="nomeVolume">{isLoading ? "Carregando" : "No Ar"}</h4>
        {!isSafari_Browser &&(
        <input
          className="sliderVolume"
          type="range"
          min="0"
          max="1"
          step="0.05"
          onChange={handleVolumeChange}
        ></input>
        )}
      </div>
      <div className="containerBotao">
        <img
          className="botaoMutarDesmutar"
          src={isMuted ? BotaoMutar : BotaoDesmutar}
          alt=""
          onClick={toggleMuteUnmute}
        />
      </div>
    </div>
  );
};

ReprodutorDeAudio.propTypes = {
  audioURL: PropTypes.string.isRequired
};

export default ReprodutorDeAudio;
