import React from "react";
import AppRoutes from "./routes";

import "./App.css";

function App() {
  return <AppRoutes/>;
}

export default App;


